/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        $(function() { 

         
          setTimeout(function () {
            $('option.gabriela').text('Gabriola');
            $("option.gabriela").click();
            $("option.gabriela").attr('selected','selected');
            $("option.alex-brush").click();
            $('option.alex-brush').change(function(){ 
              var value = $(this).val();
          });

          $('.wpc-text-layer-font-preset').val('Gabriela').change();
    
          // Optional: Ereignis auslösen, um den Wechsel zu simulieren
          $('.wpc-text-layer-font-preset').trigger('change');



            $(".subset[data-uid='zwKh-AZqo'] p").addClass("visible");
          


                    }, 2000);


          setTimeout(function () {
            
            if ($(".wpc-control-item[data-uid='zwKh-AZqo']").hasClass("current")) {

              $(".subset[data-uid='zwKh-AZqo'] p").removeClass("visible");
             
            } 
          }, 500);

          
        
        });

           
          
          $(".my-config-btn").click(function () {
            console.log("clicked btn");
            //$("[data-uid='zwKh-AZqo']").click();
            if($(".my-config-btn").hasClass('current')){
              console.log("got class btn");
              $("[data-uid='zwKh-AZqo'].image-chosen").removeClass('image-chosen');
              $(".my-config-btn").removeClass('current');
              $("[data-uid='zwKh-AZqo'].wpc-control-type-icon").slideUp();
            }else{
              console.log("no class");
              $(".my-config-btn").addClass('current');
              $("[data-uid='zwKh-AZqo'].wpc-control-type-icon").slideDown();
              $("[data-uid='zwKh-AZqo'].image-chosen").addClass('image-chosen');
              
            }
            


          });
          
          $(".wpc-control-item[data-uid='zwKh-AZqo'] input").click(function () {
           
            $(".wpc-control-item[data-uid='zwKh-AZqo']").addClass("current");
            var value = $(this).val();
            if ( value.length > 0 && value !== "Spruch" ){
              //nothing
            }else{
              $(".wpc-control-item[data-uid='zwKh-AZqo'] input").text("Spruch");
            }
            
            
          
          });
          $("[data-parent-uid='gchM-1UGd'] .wpc-control-item:not('.current')").click(function () {
            
            $("[data-parent-uid='gchM-1UGd'] .wpc-control-item[data-uid='zwKh-AZqo']").removeClass("current");
            });

         /* $(".wpc-control-item[data-uid='zwKh-AZqo']").click(function () {
            
            $(".wpc-control-item[data-uid='zwKh-AZqo']").click();
            });});
            setTimeout(function () {
              $("#shipping_method li:nth-child(2) label").text('Abholung vor Ort (10% Rabatt)');
              $("[data-uid='gchM-1UGd'] li").click();
              //$("[data-uid='zwKh-AZqo'].image-chosen").slideUp();
              // later $(".wpc-control-lists[data-parent-uid='gchM-1UGd'] .wpc-control-lists-inner").prepend("<li class='my-config-btn wpc-control-item wpc-control-type-label wpc-layer-type-image wpc-icon-round'><p class='wpc-control-item-label'><span class='wpc-icon-label-inner'><span class='wpc-control-item-icon-wrap'>T</span></span></p></li>");
            }, 500);*/
            
            //hochzeitskonfig ende
         


          
         
       

      








        // MENU
        var $menuEl = $('.menu-holder'),
          posTop;
          /*------SHOP------*/
          $('#ppcp-messages').appendTo('.woocommerce-variation-add-to-cart');
          $('.ppc-button-wrapper').appendTo('.woocommerce-variation-add-to-cart');

        /*--------------------------konfigurator---------------------------_*/
        

        setTimeout(function () {
          $("#product-32714 [data-uid='HAh0-Ghr9']").click();
          $("#product-32714 [data-uid='WWNJ-XO1X']").click();
          
          $("#product-32714 [data-uid='Zoj4-FV1C']").click();
          
        }, 500);
        
        var konfigQuery = window.matchMedia('screen and (min-width: 0px) and (max-width: 561px)');

        if (konfigQuery.matches) {

          $('.konfigurator .wpc-layer-type-image:not(".current")').click(function () {

            $('html, body').animate({
              scrollTop: $('.konfigurator').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
            }, 'fast');
          });
          $('.hochzeitskonfigurator,.deine-hochzeits-konfiguration .wpc-layer-type-image:not(".current")').click(function () {

            $('html, body').animate({
              scrollTop: $('.hochzeitskonfigurator,.deine-hochzeits-konfiguration').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
            }, 'fast');
          });
        }
        $(".scroll-more").click(function () {
          $([document.documentElement, document.body]).animate({
            scrollTop: $(".wpc-config-element.wpc-controls-parent-wrap.accordion").offset().top
          }, 100);
        });

        $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .small').addClass('selected');
        $(document).ready(function () {

          setTimeout(function () {
            $(".konfigurator .wpc-carousel-dots .wpc-carousel-dot:nth-child(2)").click();
            //Hochzeit
            //$(".deine-hochzeits-konfiguration .wpc-carousel-dots .wpc-carousel-dot:nth-child(2)").click();
            

          }, 300);
          
        });

        $("[data-text = 'Ständer-Stahl-300']").addClass("visible");

        $("[data-text = '02S']").click(function () {

          // if ($("[data-text='Material'] .current").data('text') === '02S') {
          //   $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
          // } else {
          $(".configurator-view .wpc-carousel-stage-outer").addClass("edel");


        });
        $("[data-text = '02M']").click(function () {

          // if ($("[data-text='Material'] .current").data('text') === '02M') {
          //   $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
          // } else {
          $(".configurator-view .wpc-carousel-stage-outer").addClass("edel");


        });
        $("[data-text = '02L']").click(function () {
          // if ($("[data-text='Material'] .current").data('text') === '02L') {
          //   $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
          // } else {
          $(".configurator-view .wpc-carousel-stage-outer").addClass("edel");

        });
        $("[data-text = '01S']").click(function () {

          $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
        });
        $("[data-text = '01M']").click(function () {

          $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
        });
        $("[data-text = '01L']").click(function () {

          $(".configurator-view .wpc-carousel-stage-outer").removeClass("edel");
        });

        $('.wpc-submit-cart-form.js-wpc-submit-cart-form').click(function () {
          $(".wpc-carousel-dots .wpc-carousel-dot:first-child").click();
        });
        $("[data-text = '01S']").toggleClass("visible");
        $("[data-text = '02S']").toggleClass("visible");

        $("[data-text = 'BBQ-SET300']").addClass("visible");
        $("[data-text = 'Ständer300']").addClass("visible");

        $('[data-text="01S"]').click(function () {

          if ($("[data-text='Ständer-Stahl-300']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-300']").click();

          }

        });
        $('[data-text="02S"]').click(function () {
          if ($("[data-text='Ständer-Edelstahl-300']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-300']").click();

          }
        });

        //400
        $('[data-text="01M"]').click(function () {

          if ($("[data-text='Ständer-Stahl-400']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-400']").click();

          }

        });
        $('[data-text="02M"]').click(function () {
          if ($("[data-text='Ständer-Edelstahl-400']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-400']").click();

          }
        });

        //500

        $('[data-text="01L"]').click(function () {

          if ($("[data-text='Ständer-Stahl-500']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-500']").click();

          }

        });
        $('[data-text="02L"]').click(function () {
          if ($("[data-text='Ständer-Edelstahl-500']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-500']").click();

          }
        });



        /* ---------------300----------------------*/
        $('[data-text="300"]').click(function () {

          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .middle').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .big').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .small').addClass('selected');
          $("[data-text = '01S']").addClass("visible");
          $("[data-text = '02S']").addClass("visible");
          $("[data-text = '01M']").removeClass("visible");
          $("[data-text = '02M']").removeClass("visible");
          $("[data-text = '01L']").removeClass("visible");
          $("[data-text = '02L']").removeClass("visible");
          $("[data-text = 'BBQ-SET300']").addClass("visible");
          $("[data-text = 'BBQ-SET400']").removeClass("visible");
          $("[data-text = 'BBQ-SET500']").removeClass("visible");

          if ($("[data-text='BBQ-SET300']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='BBQ-SET400']").hasClass("current")) {
            $("[data-text='BBQ-SET300']").click();

          } else if ($("[data-text='BBQ-SET500']").hasClass("current")) {
            $("[data-text='BBQ-SET300']").click();

          }
          if ($("[data-text='Ständer-Stahl-300']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-300']").click();

          } else if ($("[data-text='Ständer-Stahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-300']").click();

          }
          //oben
          if ($("[data-text='Ständer-Edelstahl-300']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-300']").click();

          } else if ($("[data-text='Ständer-Edelstahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-300']").click();

          }



          if ($("[data-text='Material'] .current").data('text') === '01M') {

            $('[data-text="01S"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '01L') {

            $('[data-text="01S"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02M') {

            $('[data-text="02S"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02L') {

            $('[data-text="02S"]').click();
          }
        });
        /*01S*/
        $('[data-text = "01S"]').click(function () {

          setTimeout(function () {

            if ($("[data-text='01S']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").addClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02S']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");

              
            }
          }, 500);
        });
        /*02S*/
        $('[data-text = "02S"]').click(function () {

          setTimeout(function () {

            if ($("[data-text='01S']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").addClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02S']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            }
          }, 500);
        });

        /* 300er unten */


        /* -----------------400----------------------*/
        $('[data-text="400"]').click(function () {


          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .small').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .big').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .middle').addClass('selected');

          $("[data-text = '01S']").removeClass("visible");
          $("[data-text = '02S']").removeClass("visible");
          $("[data-text = '01M']").addClass("visible");
          $("[data-text = '02M']").addClass("visible");
          $("[data-text = '01L']").removeClass("visible");
          $("[data-text = '02L']").removeClass("visible");
          $("[data-text = 'BBQ-SET300']").removeClass("visible");
          $("[data-text = 'BBQ-SET400']").addClass("visible");
          $("[data-text = 'BBQ-SET500']").removeClass("visible");


          if ($("[data-text='BBQ-SET400']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='BBQ-SET300']").hasClass("current")) {
            $("[data-text='BBQ-SET400']").click();

          } else if ($("[data-text='BBQ-SET500']").hasClass("current")) {
            $("[data-text='BBQ-SET400']").click();

          }
          if ($("[data-text='Ständer-Stahl-400']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-400']").click();

          } else if ($("[data-text='Ständer-Stahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-400']").click();

          }

          //oben
          if ($("[data-text='Ständer-Edelstahl-400']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-400']").click();

          } else if ($("[data-text='Ständer-Edelstahl-500']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-400']").click();

          }


          if ($("[data-text='Material'] .current").data('text') === '01S') {

            $('[data-text="01M"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '01L') {

            $('[data-text="01M"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02S') {

            $('[data-text="02M"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02L') {

            $('[data-text="02M"]').click();
          }


        });
        /*01M*/
        $('[data-text = "01M"]').click(function () {

          setTimeout(function () {

            if ($("[data-text='01M']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").addClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02M']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            }
          }, 500);
        });
        /*02M*/
        $('[data-text = "02M"]').click(function () {

          setTimeout(function () {

            if ($("[data-text='01M']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").addClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02M']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            }
          }, 500);
        });
        /*Ende 400er*/






        /* -----------------500----------------------*/
        $('[data-text="500"]').click(function () {
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .small').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .middle').removeClass('selected');
          $('.wpc-controls-wrap .wpc-layer-title-wrap .sizes .big').addClass('selected');
          $("[data-text = '01S']").removeClass("visible");
          $("[data-text = '02S']").removeClass("visible");
          $("[data-text = '01M']").removeClass("visible");
          $("[data-text = '02M']").removeClass("visible");
          $("[data-text = '01L']").addClass("visible");
          $("[data-text = '02L']").addClass("visible");
          $("[data-text = 'BBQ-SET300']").removeClass("visible");
          $("[data-text = 'BBQ-SET400']").removeClass("visible");
          $("[data-text = 'BBQ-SET500']").addClass("visible");

          if ($("[data-text='BBQ-SET500']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='BBQ-SET300']").hasClass("current")) {
            $("[data-text='BBQ-SET500']").click();

          } else if ($("[data-text='BBQ-SET400']").hasClass("current")) {
            $("[data-text='BBQ-SET500']").click();

          }
          if ($("[data-text='Ständer-Stahl-500']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Stahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-500']").click();

          } else if ($("[data-text='Ständer-Stahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Stahl-500']").click();

          }

          //oben
          if ($("[data-text='Ständer-Edelstahl-500']").hasClass("current")) {
            //nothing

          } else if ($("[data-text='Ständer-Edelstahl-300']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-500']").click();

          } else if ($("[data-text='Ständer-Edelstahl-400']").hasClass("current")) {
            $("[data-text='Ständer-Edelstahl-500']").click();

          }



          if ($("[data-text='Material'] .current").data('text') === '01S') {

            $('[data-text="01L"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '01M') {

            $('[data-text="01L"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02S') {

            $('[data-text="02L"]').click();
          }
          if ($("[data-text='Material'] .current").data('text') === '02M') {

            $('[data-text="02L"]').click();
          }
        });

        /*01L*/
        $('[data-text = "01L"]').click(function () {


          setTimeout(function () {

            if ($("[data-text='01L']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02L']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").addClass("visible");
            }
          }, 500);
        });

        /*02L*/
        $('[data-text = "02L"]').click(function () {


          setTimeout(function () {

            if ($("[data-text='01L']").hasClass("current")) {


              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").addClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").removeClass("visible");
            } else if ($("[data-text='02L']").hasClass("current")) {

              $("[data-text = 'Ständer-Stahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Stahl-500']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-300']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-400']").removeClass("visible");
              $("[data-text = 'Ständer-Edelstahl-500']").addClass("visible");
            }
          }, 500);
        });

        /*ENde 500er*/


        //ZubehÃ¶r

        $('<span class="bbq-anchor wpc-layer-title-desc"><a target="_blank" href="https://ghettofassl.de/produkt/bbq/">Mehr Informationen zu unserem BBQ-SET</a></span>').insertAfter($("[data-parent-uid = 'RI9C-jiB0']"));

        $('<span class="staender-anchor wpc-layer-title-desc"><a target="_blank" href="https://ghettofassl.de/produkt/staender/">Mehr Informationen zu unserem Ständer</a></span>').insertAfter($("[data-parent-uid = 'EY4t-h1Ug']"));
       
       






        $(".woocommerce-cart .wc-cart-shipping-notice").append($(".woocommerce-cart .coupon"));
        $(".konfigurator button.no-design").click(function () {
          window.location.href = '/individuelle-anfrage';
          return false;

        });

        //MENU
        $('body').on('click', '.menu-trigger', function (e) {
          e.stopImmediatePropagation();
          posTop = $(window).scrollTop();
          $(this).addClass('is-active');

          $menuEl.fadeIn(600, function () {
            $('body').addClass('animate-menu');
          });
          setTimeout(function () {
            $('body').addClass('animate-complete menu-open');
          }, 1600);
        });

        $('.close-menu').click(function () {
          var offset = Modernizr.touchevents ? $(window).width() > 991 ? 1600 : 0 : 1600;
          $('body').removeClass('menu-open animate-complete');
          $('html, body').animate({
            scrollTop: posTop
          }, 10);
          $('body').removeClass('animate-menu');
          setTimeout(function () {
            $menuEl.fadeOut(200);
            $('.menu-trigger').removeClass('is-active');
          }, offset);
        });
        // END OF MENU

        $(document).scroll(function () {
          var $nav = $(".menu-holder");
          var $logo = $(".logo");
          $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
          $logo.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });


        // JavaScript to be fired on all pages
        //Animations
        new WOW().init();


        //accordion
        $('[data-action-toggle]').click(function (e) {
          e.stopImmediatePropagation();
          var $target = $(this).attr('data-action-toggle'),
            textOpen = $(this).attr('data-text-open'),
            textClose = $(this).attr('data-text-close');


          $(this).toggleClass('_active').parents('section').find($target).slideToggle();

          if ($(this).find('span').text() !== textOpen) {
            $(this).find('span').text(textOpen);
          } else {
            $(this).find('span').text(textClose);
          }
        });


        //navigation
        $(".hamburger").on('click', function () {  //// use a class, since your ID gets mangled
          $(this).toggleClass('is-active');
          $('body').toggleClass("hidden");
          $('.logo').toggleClass("full-menu-logo");
          $('.menu-holder').toggleClass("full-menu");
          $('.menu-holder .col-xl-9.col-md-8').toggleClass("display-nav");
        });
        $("#menu-primary-navigation li a").on('click', function () {
          $('body').removeClass("hidden");
          $('.menu-holder').removeClass("full-menu");
          $('.menu-holder .col-xl-9.col-md-8').removeClass("display-nav");
          $(".hamburger").removeClass('is-active');
        });
        $("#menu-primary-navigation-en li a").on('click', function () {
          $('body').removeClass("hidden");
          $('.menu-holder').removeClass("full-menu");
          $('.menu-holder .col-xl-9.col-md-8').removeClass("display-nav");
          $(".hamburger").removeClass('is-active');
        });

        var mobileNavQuery = window.matchMedia('screen and (min-width: 0px) and (max-width: 1023px)');
        var $navKontaktButton = $('.nav-menu .menu-btn-blue');
        $navKontaktButton.on('click', function () {
          if (mobileNavQuery.matches) {
            $('.hamburger.is-active').trigger('click');
          }
        });

        //knowfrom swiper
        var swiper = new Swiper('.knownfrom-swiper', {
          slidesPerView: 1,
          loop: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          breakpoints: {
            // when window width is <= 1200px
            1200: {
              slidesPerView: 5,
              spaceBetween: 100,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 25,
            }
          }
        });

        //Homegrid
        $(".hg_item").hover(function () {
          $(this).find(".hg-heading").addClass("gridhover");
        }, function () {
          $(this).find(".hg-heading").removeClass("gridhover");
        }
        );

        //Showroom Swiper
        var swiper2 = new Swiper('.showroom-swiper', {
          slidesPerView: 1,
          spaceBetween: 20,
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            // when window width is <= 1200px
            1200: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            }
          }
        });
        //product Swiper
        var swiper8 = new Swiper('.product-swiper', {
          slidesPerView: 1,
          spaceBetween: 20,
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            // when window width is <= 1200px
            1200: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            }
          }
        });


        $(function () {
          // (Optional) Active an item if it has the class "is-active"
          $(".accordion > .accordion-item.is-active").children(".accordion-panel").slideDown();

          $(".accordion > .accordion-item").click(function () {
            // Cancel the siblings
            $(this).siblings(".accordion-item").removeClass("is-active").children(".accordion-panel").slideUp();
            // Toggle the item
            $(this).toggleClass("is-active").children(".accordion-panel").slideToggle('ease-out');
          });
        });

        $productheight = $('.single-product .woocommerce-product-gallery__image').height();
        $('.single-product .summary').css('height', $productheight);


        $(window).scroll(function () {

          var top_of_element = $("footer").offset().top;
          var bottom_of_element = $("footer").offset().top + $("footer").outerHeight();
          var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
          var top_of_screen = $(window).scrollTop();

          if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            // the element is visible, do something
            $('#mybear').css("bottom", "200px");
            $('#cancel-bear').css("bottom", "400px");


          } else {
            // the element is not visible, do something else
            $('#mybear').css("bottom", "30px");
            $('#cancel-bear').css("bottom", "230px");
          }
        });
        var hovermobile = window.matchMedia('(min-width: 567px)');
        if (hovermobile.matches) {
          $('.archive .sections-holder .products .product').hover(
            function () {
              $(this).children(".add_to_cart_button").slideDown();
            }, function () {
              $(this).children(".add_to_cart_button").slideUp();
            }
          );
          $('.single-product .sections-holder .up-sells .product').hover(
            function () {
              $(this).children(".product_type_variable").slideDown();
            }, function () {
              $(this).children(".product_type_variable").slideUp();
            }
          );
          $('.single-product .sections-holder .related .product').hover(
            function () {
              $(this).children(".product_type_variable").slideDown();

            }, function () {
              $(this).children(".product_type_variable").slideUp();
            }
          );
          $('.archive .sections-holder .products  .product').hover(
            function () {
              $(this).children(".product_type_variable").slideDown();

            }, function () {
              $(this).children(".product_type_variable").slideUp();
            }
          );
        }


        var mobile = window.matchMedia('(min-width: 0px) and (max-width: 1399px)');
        if (mobile.matches) {
          $("#vardesc").detach().appendTo('.my-desc-full-mobile');
        } else {
          $("#vardesc").detach().appendTo('#variable-desc');
        }

        var slideDownVar;

        function variations() {
          var a = [];
          $('.product .variations .selected').each(function () {
            a.push($(this).data("value"));
          });
          slideDownVar = a.join('.');
        }

        function slideDown(slideDownVar) {
          $(".product .summary .cart").addClass('mobile-slide');
          $(".woocommerce-variation-price").slideDown();
          $(".vari-desc").slideUp();

        }

        //pageload
        variations();
        slideDown();


        //variation desc
        var value;

        $(".product .variations .variable-item").click(function () {
          $(".my-desc-normal").slideUp();
          $('.my-desc .my-desc-short').slideUp();

          setTimeout(function () {
            var varShortDesc = $(".woocommerce-variation-short-description").text();
            $('.my-desc .my-desc-short').html(varShortDesc);
            $('.my-desc .my-desc-short').slideDown();
            variations();
            slideDown();
            $(".vari-desc." + slideDownVar).slideDown();

          }, 500);

        });


        $(".product .cart .reset_variations").click(function () {
          $(".my-desc-normal").slideDown();
          $(".product .summary .cart.mobile-slide").removeClass('mobile-slide');
          $(".woocommerce-variation-price").slideUp();
          $('.my-desc .my-desc-short').slideUp();
        });


        //Variation container height

        var varcount = $(".product .variations tbody").children().length;

        if (varcount === 3
        ) {
          $(".product .variations").addClass("ThreeVar");
        }
        if (varcount === 2) {
          $(".product .variations").addClass("TwoVar");
        }
        if (varcount === 1) {
          $(".product .variations").addClass("OneVar");
        }

        $("[data-country-code='de']").click();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home':
    {
      init: function () {
        // JavaScript to be fired on the home page


      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_product':
    {
      init: function () {
        // JavaScript to be fired on the about us page


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

}
)
  (jQuery); // Fully reference jQuery after this point.